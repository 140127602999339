.ant-drawer-body {
  padding: 0px !important;
}

.ant-drawer-header-title > button {
  color: white !important;
}

.ant-drawer-title {
  color: white !important;
}

.ant-scroll-number {
  scale: 0.7 !important;
}

/* .ant-drawer-content {
  height: 95vh !important;
} */

@media (min-width: 640px) {
  .ant-drawer-header {
    display: none !important;
  }
}
